import React from 'react'
import * as S from './_styles'

import { Button } from '@interco/inter-ui/components/Button'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import IconGreenBg from '../../components/IconGreenBg/_index'

type SemanaDoEmpreendedorSectionProps = {
  setIsModalOpen: (modalState: boolean) => void;
  setModalDatalayerSection: (section: string) => void;
}

const SemanaDoEmpreendedorSection = ({
  setIsModalOpen,
  setModalDatalayerSection,
}: SemanaDoEmpreendedorSectionProps) => {
  const [ sendDataLayer ] = useDataLayer()

  return (
    <S.SectionWrapper
      className='section_wrapper'
      id='semana_do_empreendedor'
    >
      <img
        className='section_wrapper__graphism--left'
        src='https://central-imagens.bancointer.com.br/images-without-small-versions/semana-empresa-hero-grafismo1/image.webp'
        alt='grafismos da identidade visual da semana do empreendedor 2024'
        loading='lazy'
      />
      <img
        className='section_wrapper__graphism--right'
        src='https://central-imagens.bancointer.com.br/images-without-small-versions/semana-empresa-hero-grafismo2/image.webp'
        alt='grafismos da identidade visual da semana do empreendedor 2024'
        loading='lazy'
      />

      <div className='container'>
        <div className='row'>
          <div className='section_wrapper__content'>
            <picture className='section_wrapper__content__title_container'>
              <img
                className='section_wrapper__content__title_container__title'
                src='https://central-imagens.bancointer.com.br/images-without-small-versions/semana-empresa-hero/image.webp'
                alt='grafismos da identidade visual da semana do empreendedor 2024'
                aria-label='Semana do Empreendedor 2024'
                role='heading'
                aria-level={1}
              />
            </picture>

            <div className='section_wrapper__content__description'>
              <IconGreenBg
                icon='ic_calendar'
                iconPath='action-navigation/'
              />
              <p className='section_wrapper__content__description--date'>
                14 a 18 de outubro
              </p>
              <p className='section_wrapper__content__description--online_free_event'>
                Evento 100% gratuito e online
              </p>
            </div>

            <Button
              className='section_wrapper__content__participate_button'
              fullWidth
              onClick={() => {
                setModalDatalayerSection('m_01')
                setIsModalOpen(true)
                sendDataLayer({
                  section: 'dobra_01',
                  section_name: 'Semana do Empreendedor',
                  element_name: 'Quero participar',
                  element_action: 'click button',
                })
              }}
            >
              Quero participar
            </Button>
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default SemanaDoEmpreendedorSection
