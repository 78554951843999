import React from 'react'

import * as S from './_styles'

import { Button } from '@interco/inter-ui/components/Button'
import useWidth from 'src/hooks/window/useWidth'
import useDomReady from 'src/hooks/window/useDomReady'
import useDataLayerHeader from 'src/hooks/useDataLayer/dataLayerHeader'

const CustomHeader = () => {
  const [ sendDataLayerHeader ] = useDataLayerHeader()
  const width = useWidth()
  const domReady = useDomReady()

  return (
    <>
      <S.CustomHeaderWrapper
        className='section_wrapper'
        id='custom-header'
      >
        <img
          src='https://central-imagens.bancointer.com.br/images-without-small-versions/semana-empresa-logo/image.webp'
          alt='Logo Inter Empresas'
          className='section_wrapper__logo'
        />
        { width >= 768 && domReady && (
          <div className='section_wrapper__options'>
            <Button
              as='a'
              target='_blank'
              href='https://inter.co/empresas/conta-digital/pessoa-juridica/'
              className='section_wrapper__options__buttons'
              variant='secondary'
              onClick={() => {
                sendDataLayerHeader({
                  c_page: 'https://inter.co/empresas/semana-do-empreendedor',
                  element_action: 'click button',
                  element_name: 'Conheça a Conta PJ',
                  redirect_url: 'https://inter.co/empresas/conta-digital/pessoa-juridica/',
                })
              }}
            >
              Conheça a Conta PJ
            </Button>
            <Button
              as='a'
              target='_blank'
              href='https://www.youtube.com/watch?v=DQcihNib0oY&list=PLuZu2Av0_IiOaOkjhJaSjVeALaEG_gWUD&pp=iAQB'
              className='section_wrapper__options__buttons'
              variant='primary'
              onClick={() => {
                sendDataLayerHeader({
                  c_page: 'https://inter.co/empresas/semana-do-empreendedor',
                  element_action: 'click button',
                  element_name: 'Acesse o canal do youtube',
                  redirect_url: 'https://www.youtube.com/watch?v=DQcihNib0oY&list=PLuZu2Av0_IiOaOkjhJaSjVeALaEG_gWUD&pp=iAQB',
                })
              }}
            >
              Acesse o canal do youtube
            </Button>
          </div>
          )
        }
      </S.CustomHeaderWrapper>
      <S.BlankSpace />
    </>
  )
}

export default CustomHeader
