import React from 'react'

import * as S from './_styles'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import IconGreenBg from '../../components/IconGreenBg/_index'
import { Button } from '@interco/inter-ui/components/Button'

const AcompanheNossasRedes = () => {
  const [ sendDataLayer ] = useDataLayer()

  type socialMediasOrNewsletterCardsProps = {
    icon: string;
    iconPath: string;
    title: string;
    buttonText: string;
    redirectUrl: string;
  }

  const socialMediasOrNewsletterCards: socialMediasOrNewsletterCardsProps[] = [
    {
      icon: 'ic_smartphone',
      iconPath: 'media-communication/',
      title: 'Acompanhe nossas redes sociais e fique por dentro das novidades do Inter Empresas',
      buttonText: 'Quero seguir',
      redirectUrl: 'https://www.instagram.com/interempresas/',
    },
    {
      icon: 'ic_envelope',
      iconPath: 'media-communication/',
      title: 'Inscreva-se na Newsletter e receba mensalmente novidades sobre o mercado empresarial',
      buttonText: 'Quero me inscrever',
      redirectUrl: 'https://inter.co/empresas/conta-digital/pessoa-juridica/#formulario',
    },
  ]

  return (
    <S.AcompanheNossasRedes
      className='section_wrapper'
      id='acompanhe-nossas-redes'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 section_wrapper__content'>
            <picture className='section_wrapper__content__graphism'>
              <source
                srcSet='https://central-imagens.bancointer.com.br/images-without-small-versions/semana-empresa-dobra4-graf/image.webp'
                media='(min-width: 768px)'
              />
              <img
                className='section_wrapper__content__graphism__source'
                src='https://central-imagens.bancointer.com.br/images-without-small-versions/semana-empresa-dobra4-grafismo-mobile/image.webp'
                alt='grafismos da identidade visual da semana do empreendedor 2024.'
                loading='lazy'
              />
            </picture>
            <div className='section_wrapper__content__texts'>
              {socialMediasOrNewsletterCards.map((card: socialMediasOrNewsletterCardsProps, index: number) => (
                <article
                  className={`section_wrapper__content__texts__article section_wrapper__content__texts__article--${index}`}
                  key={`article--${card.title}`}
                >
                  <IconGreenBg
                    containerClassName='section_wrapper__content__texts__article__icon_background'
                    iconClassName='section_wrapper__content__texts__article__icon_background__icon'
                    icon={card.icon}
                    iconPath={card.iconPath}
                    marginRight='4'
                  />
                  <h3 className='section_wrapper__content__texts__article__title'>
                    {card.title}
                  </h3>
                  <Button
                    as='a'
                    href={card.redirectUrl}
                    target='_blank'
                    className='section_wrapper__content__texts__article__button'
                    fullWidth
                    onClick={() => {
                      sendDataLayer({
                        section: 'dobra_04',
                        section_name: 'Acompanhe nossas redes sociais',
                        element_name: card.buttonText,
                        element_action: 'click button',
                        redirect_url: card.redirectUrl,
                      })
                    }}
                  >
                    {card.buttonText}
                  </Button>
                </article>
              ))}
            </div>
          </div>
        </div>
      </div>
    </S.AcompanheNossasRedes>
  )
}

export default AcompanheNossasRedes
