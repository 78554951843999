import React from "react"

import { initTheme, Theme } from "@interco/inter-ui"

import BaseLayout from "src/layouts/BaseLayout"
import CustomHeader from "./components/CustomHeader/_index"
import pageContext from './pageContext.json'

import S1_SemanaDoEmpreendedorSection from "./sections/1-SemanaDoEmpreendedor/_index"
import S2_UmaSemanaDeLivesEOfertas from "./sections/2-UmaSemanaDeLivesEOfertas/_index"
import S3_ValeAPenaVerDeNovo from "./sections/3-ValeAPenaVerDeNovo/_index"
import S4_AcompanheNossasRedes from "./sections/4-AcompanheNossasRedes/_index"
import S5_ContaPj100Gratuita from "./sections/5-ContaPj100Gratuita/_index"
import EventRegisterForm from "./components/EventRegisterForm/_index"

const SemanaDoEmpreendedor = () => {
  const [ isFormOpen, setIsFormOpen ] = React.useState<boolean>(false)
  const [ modalDatalayerSection, setModalDatalayerSection ] = React.useState<string>('')

  React.useEffect(() => {
    initTheme(Theme.PF)
  })

  return (
    <BaseLayout pageContext={pageContext}>
      <EventRegisterForm
        openModal={isFormOpen}
        modalDatalayerSection={modalDatalayerSection}
        setIsModalOpen={setIsFormOpen}
      />
      <CustomHeader />
      <S1_SemanaDoEmpreendedorSection
        setIsModalOpen={setIsFormOpen}
        setModalDatalayerSection={setModalDatalayerSection}
      />
      <S2_UmaSemanaDeLivesEOfertas
        setIsModalOpen={setIsFormOpen}
        setModalDatalayerSection={setModalDatalayerSection}
      />
      <S3_ValeAPenaVerDeNovo />
      <S4_AcompanheNossasRedes />
      <S5_ContaPj100Gratuita />
    </BaseLayout>
  )
}

export default SemanaDoEmpreendedor
