import React, { useState } from 'react'

import * as S from './_styles'

import * as newColors from 'src/styles/newColors'
import * as oldColors from 'src/styles/colors'
import * as URLS from 'src/config/api/Urls'

import { COMMON_MASKS, Input } from '@interco/inter-ui/components/Input'
import { Radio } from '@interco/inter-ui/components/Radio'
import { Button } from '@interco/inter-ui/components/Button'
import AcceptTerms from 'src/components/AcceptTerms'

import IcClose from '@interco/icons/core/action-navigation/ic_close'
import IcCloseCircle from '@interco/icons/core/status/ic_close_circle'
import IcCheckCircle from '@interco/icons/core/status/ic_check_circle'

import { useForm, UseFormMethods } from 'react-hook-form'
import useDomReady from 'src/hooks/window/useDomReady'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { Modal } from 'src/components/Modal'

import { getParameterByName } from 'src/shared/helpers'
import axios from 'axios'

type FormValuesProps = {
  name: string;
  email: string;
  cpf: string;
  activeCnpj: boolean;
  acceptTerms: boolean;
}

type FeedBackStatusProps = 'success' | 'error' | 'notSent';

type EventRegisterFormProps = {
  openModal: boolean;
  modalDatalayerSection: string;
  setIsModalOpen: (modalState: boolean) => void;
}

const EventRegisterForm = ({
  openModal,
  modalDatalayerSection,
  setIsModalOpen,
}: EventRegisterFormProps) => {
  const { handleSubmit, register, errors, reset }: UseFormMethods<FormValuesProps> = useForm<FormValuesProps>()

  const [ activeCnpj, setActiveCnpj ] = React.useState<boolean>(false)
  const [ acceptedTerms, setAcceptedTerms ] = React.useState<boolean>(false)
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ feedBackStatus, setFeedbackStatus ] = useState<FeedBackStatusProps>('notSent')

  const [ sendDataLayer ] = useDataLayer()
  const domReady = useDomReady()

  function resetFormAndClose () {
    setIsModalOpen(false)
    setFeedbackStatus('notSent')
    setActiveCnpj(false)
    setAcceptedTerms(false)
    setActiveCnpj(false)
    reset()
  }

  const onSubmit = async (data: FormValuesProps) => {
    setLoading(true)

    const formData = {
      campanha: 'Semana do Empreendedor 2024',
      nome: data.name,
      email: data.email,
      CpfCnpj: data.cpf,
      aceite: true,
      descricao: activeCnpj ? 'Sim' : 'Não',
      conteudo01: getParameterByName('utm_source', window.location.href),
      conteudo02: getParameterByName('utm_medium', window.location.href),
      conteudo03: getParameterByName('utm_campaign', window.location.href),
    }

    await axios.post(`${URLS.CONTACT_FORM_POST_V5}/lp-mktcloud-inter`, [ formData ], {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(() => setFeedbackStatus('success'))
      .catch(() => setFeedbackStatus('error'))
      .finally(() => {
        setLoading(false)
      })
  }

  const form = domReady && (
    <>
      <h2 className='section_wrapper__title'>
        Cadastre-se gratuitamente e receba em primeira mão quem serão os convidados.
      </h2>
      <form
        id='event-register-form'
        className='section_wrapper__form'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          className='content_wrapper__form__input'
          id='name'
          name='name'
          label='Nome'
          placeholder='Digite seu nome'
          infoText={errors.name?.message}
          type='text'
          error={!!errors.name}
          ref={register({
            required: 'Digite seu nome completo',
            validate: {
              noNumbers: (value: string) => !/\d/.test(value) || 'Digite um nome válido',
              isComplete: (value: string) => value.split(' ').length > 1 || 'Digite seu nome completo',
            },
          })}
        />

        <Input
          className='content_wrapper__form__input'
          id='email'
          name='email'
          label='E-mail'
          placeholder='Digite seu e-mail'
          infoText={errors.email?.message}
          type='email'
          error={!!errors.email}
          ref={register({
            required: 'Digite um e-mail válido',
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: 'Digite um e-mail válido',
            },
          })}
        />

        <Input
          className='content_wrapper__form__input'
          id='cpf'
          name='cpf'
          label='CPF'
          placeholder='000.000.000-00'
          infoText={errors.cpf?.message}
          error={!!errors.cpf}
          mask={COMMON_MASKS.CPF}
          ref={register({
            required: 'Digite um CPF válido',
            validate: (value: string) => value.length === 14 || 'Digite um CPF válido',
          }) }
        />

        <div className='section_wrapper__form__active_cnpj'>
          <label htmlFor='' className='section_wrapper__form__active_cnpj__label'>
            Possui CNPJ ativo?
          </label>
          <div className='section_wrapper__form__active_cnpj__radios'>
            <Radio
              className='section_wrapper__form__active_cnpj__radios--yes'
              id='activeCnpj--sim'
              value='sim'
              name='sim'
              label='Sim'
              checked={activeCnpj}
              onChange={() => setActiveCnpj(true)}
            />

            <Radio
              className='section_wrapper__form__active_cnpj__radios--no'
              id='activeCnpj--nao'
              value='nao'
              name='nao'
              label='Não'
              checked={!activeCnpj}
              onChange={() => setActiveCnpj(false)}
            />
          </div>
        </div>

        <AcceptTerms
          label='Autorizo o Inter a tratar meus dados pessoais para envio de comunicações sobre seus produtos e serviços e também estou de acordo com a '
          accept={acceptedTerms}
          setAccept={setAcceptedTerms}
        />

        <Button
          isLoading={loading}
          disabled={!acceptedTerms}
          fullWidth
          as='button'
          type='submit'
          className='section_wrapper__form__subscribe'
          onClick={() => {
            sendDataLayer({
              section: `${modalDatalayerSection}`,
              section_name: 'Cadastre-se gratuitamente e receba em primeira mão quem serão os convidados.',
              element_name: 'Confirmar Inscrição',
              element_action: 'submit',
            })
          }}
        >
          Confirmar Inscrição
        </Button>
      </form>
    </>
  )

  const successScreen = domReady && (
    <S.SubmitFeedbackScreen
      className='feedback_screen'
    >
      <IcCheckCircle
        height={24}
        width={24}
        color={oldColors.green.dark}
        className='feedback_screen__icon'
      />
      <h3 className='feedback_screen__title'>
        Parabéns, seu cadastro foi realizado com sucesso!
      </h3>
      <p className='feedback_screen__description'>
        Fique atento à sua caixa de emails. Em breve você receberá mais informações.
      </p>
      <Button
        fullWidth
        as='button'
        onClick={() => {
          resetFormAndClose()
          sendDataLayer({
            section: `${modalDatalayerSection}`,
            section_name: 'Parabéns, seu cadastro foi realizado com sucesso!',
            element_name: 'Entendi',
            element_action: 'click button',
          })
        }}
        className='feedback_screen__understood'
      >
        Entendi
      </Button>
    </S.SubmitFeedbackScreen>
  )

  const errorScreen = domReady && (
    <S.SubmitFeedbackScreen>
      <IcCloseCircle
        height={24}
        width={24}
        color={oldColors.red.dark}
        className='feedback_screen__icon'
      />
      <h3 className='feedback_screen__title'>
        Tente novamente!
      </h3>
      <p className='feedback_screen__description'>
        Parece que o seu cadastro não foi concluído! Volte e tente novamente.
      </p>
      <Button
        fullWidth
        as='button'
        onClick={() => {
          resetFormAndClose()
          sendDataLayer({
            section: `${modalDatalayerSection}`,
            section_name: 'Tente novamente!',
            element_name: 'Entendi',
            element_action: 'click button',
          })
        }}
        className='feedback_screen__understood'
      >
        Entendi
      </Button>
    </S.SubmitFeedbackScreen>
  )

  function renderFormOrFeedbackScreen (state: FeedBackStatusProps) {
    switch (state) {
      case 'success':
        return successScreen
      case 'error':
        return errorScreen
      case 'notSent':
        return form
      default:
        return form
    }
  }

  return domReady && (
    <Modal
      isModalOpen={openModal}
      setIsModalOpen={setIsModalOpen}
      locationToRender={window.document.body}
    >
      <S.EventRegisterFormWrapper
        id='event-register-form'
        className='section_wrapper'
      >
        <IcClose
          width={24}
          height={24}
          color={newColors.orange.extra}
          onClick={() => {
            resetFormAndClose()
            sendDataLayer({
              section: `${modalDatalayerSection}`,
              section_name: 'Modal formulário',
              element_name: 'Fechar',
              element_action: 'click button',
            })
          }}
        />
        { renderFormOrFeedbackScreen(feedBackStatus) }
      </S.EventRegisterFormWrapper>
    </Modal>
  )
}

export default EventRegisterForm
