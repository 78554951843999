import styled from "styled-components"
import * as newColors from 'src/styles/newColors'
import { device } from "src/styles/breakpoints"

export const SectionWrapper = styled.section`
  background-color: ${newColors.orange.dark};
  padding: 32px 0;

  @media ${device.tablet}{
    padding: 40px 0;
  }
  
  @media ${device.desktopLG} {
    padding: 90px 0;
    position: relative;
  }

  .section_wrapper {
    &__graphism {

      &--right {
        display: none;

        @media ${device.desktopLG} {
          display: flex;
          position: absolute;
          bottom: 3%;
          right: 2%;
        }

        @media ${device.desktopXXL} {
          width: 19%;
          max-width: 285px;
        }
      }
      
      &--left {
        display: none;
      
        @media ${device.desktopLG} {
          display: flex;
          position: absolute;
          top: 3%;
          left: 2%;
        }

        @media ${device.desktopXXL} {
          width: 19%;
          max-width: 285px;
        }
      }
    }

    &__content {
      width: 100%;
      margin: 0 auto;

      @media ${device.tablet} {
        width: 360px;
      }
        
      &__title_container {
        display: flex;
        justify-content: center;
        width: 100%;
        
        &__title {
          width: 81%;
          
          @media ${device.tablet}{
            width: 59%;
          }

          @media ${device.desktopLG} {
            width: 100%;
          }
        }
      }

      &__description {
        background-color: ${newColors.sand};
        border-radius: 16px;
        margin: 24px 0;
        padding: 14px 24px;

        @media ${device.tablet} {
          margin-top: 32px;
        }

        @media ${device.desktopLG} {
          margin: 40px 0 16px 0;
        }

        &--date, &--online_free_event {
          font-size: 17px;
          font-family: 'Inter', sans-serif;
          line-height: 22px;
          margin: 0;
          color: ${newColors.orange.dark};

          @media ${device.desktopLG} {
            font-size: 18px;
            line-height: 22px;
          }
        }
        
        &--date{
          font-weight: 600;
          margin: 24px 0 8px 0;
        }

        &--online_free_event{
          font-weight: 400;
        }
      }
    }
  }
`
