import React from 'react'

import * as S from './_styles'

import { Button } from '@interco/inter-ui/components/Button'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type UmaSemanaDeLivesEOfertasProps = {
  setIsModalOpen: (modalState: boolean) => void;
  setModalDatalayerSection: (section: string) => void;
}

const UmaSemanaDeLivesEOfertas = ({
  setIsModalOpen,
  setModalDatalayerSection,
}: UmaSemanaDeLivesEOfertasProps) => {
  const [ sendDataLayer ] = useDataLayer()

  return (
    <S.UmaSemanaDeLivesEOfertasSection
      className='section_wrapper'
      id='uma-semana-de-lives-e-ofertas'
    >
      <div className='container'>
        <div className='row section_wrapper__content'>
          <picture className='section_wrapper__content__graphism'>
            <img
              src='https://central-imagens.bancointer.com.br/images-without-small-versions/semana-empresa-dobra2-grafismo/image.webp'
              alt='grafismos da identidade visual da semana do empreendedor 2024'
              className='section_wrapper__content__graphism__source'
              loading='lazy'
            />
          </picture>
          <div className='section_wrapper__content__texts'>
            <h2 className='section_wrapper__content__texts__title'>
              Uma semana de lives e ofertas imperdíveis pro seu negócio
            </h2>
            <p className='section_wrapper__content__texts__description'>
              <strong>Faça sua inscrição gratuita </strong>e receba em primeira mão os convidados da edição 2024 + benefício exclusivo.
            </p>
            <Button
              fullWidth
              onClick={() => {
                setModalDatalayerSection('m_02')
                setIsModalOpen(true)
                sendDataLayer({
                  section: 'dobra_02',
                  section_name: 'Uma semana de lives e ofertas imperdíveis pro seu negócio',
                  element_name: 'Inscrever',
                  element_action: 'click button',
                })
              }}
            >
              Inscrever
            </Button>
          </div>
        </div>
      </div>
    </S.UmaSemanaDeLivesEOfertasSection>
  )
}

export default UmaSemanaDeLivesEOfertas
