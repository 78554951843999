import React from 'react'

import * as S from './_styles'

import { Button } from '@interco/inter-ui/components/Button'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const ContaPj100Gratuita = () => {
  const [ sendDataLayer ] = useDataLayer()

  return (
    <S.ContaPj100GratuitaSection
      className='section_wrapper'
      id='conta-pj-100-gratuita'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 section_wrapper__content'>
            <img
              className='section_wrapper__content__image'
              src='https://central-imagens.bancointer.com.br/images-without-small-versions/semana-empresa-dobra5/image.webp'
              alt='Cartão Inter Empresas ao lado de um smartphone dando destaque ao app Inter Empresas.'
              loading='lazy'
            />
            <div className='section_wrapper__content__texts'>

              <h2 className='section_wrapper__content__texts__title'>
                Conta PJ 100% gratuita é Inter Empresas
              </h2>
              <p className='section_wrapper__content__texts__description'>
                Abra sua conta gratuita e aproveite um Super App completo com cartão sem taxas, programa de pontos gratuitos, Pix gratuito e ilimitado, boletos grátis todo mês e muito mais!
              </p>
              <Button
                className='section_wrapper__content__texts__button'
                fullWidth
                as='a'
                target='_blank'
                href='https://conta-digital-pj.inter.co/login?utm_source=site&utm_medium=botao&utm_campaign=aquisicao_semana_empreendedor&utm_term=conta-digital-pj&utm_content=LPSemanaDoEmpreendedor'
                onClick={() => {
                  sendDataLayer({
                    section: 'dobra_05',
                    section_name: 'Conta PJ 100% gratuita é Inter Empresas',
                    element_name: 'Quero experimentar',
                    element_action: 'click button',
                    redirect_url: 'https://conta-digital-pj.inter.co/login?utm_source=site&utm_medium=botao&utm_campaign=aquisicao_semana_empreendedor&utm_term=conta-digital-pj&utm_content=LPSemanaDoEmpreendedor',
                  })
                }}
              >
                Quero experimentar!
              </Button>
            </div>
          </div>
        </div>
      </div>
    </S.ContaPj100GratuitaSection>
  )
}

export default ContaPj100Gratuita
