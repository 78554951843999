import { device } from "src/styles/breakpoints"
import styled from "styled-components"

export const CustomHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 20;
  width: 100%;
  padding: 16px 0;  

  background-color: white;

  @media ${device.tablet} {
    align-items: center;
    flex-direction: column;
  }

  @media ${device.desktopLG} {
    padding: 32px 44px;
    flex-direction: row;
    justify-content: space-between;
  }

  @media ${device.desktopXXL} {
    padding: 36px 156px;
  }

  .section_wrapper {
    &__logo {
      height: 24px;
      width: auto;

      @media ${device.tablet} {
        margin-bottom: 16px;
      }
      
      @media ${device.desktopLG} {
        height: 34px;
        margin: 0;
      }

      @media ${device.desktopXXL} {
        height: 44px;
      }
    }

    &__options {
      display: flex;
      flex-direction: row;
      justify-content: center;
      
      height: fit-content;
      width: 100%;
      
      @media ${device.desktopLG} {
        width: 55%;
      }

      @media ${device.desktopXXL} {
        width: 50%;
      }

      &__buttons {
        margin: 0 8px ;
        padding: 10px 0;
        width: 34%;

        @media ${device.desktopLG} {
          padding: 16px 0;
          width: 50%;
        }
      }
    }
  }
`

export const BlankSpace = styled.div`
  height: 56px;
  width: 100%;

  @media ${device.tablet} {
    height: 117.5px;
  }

  @media ${device.desktopLG} {
    height: 112px;
  }

  @media ${device.desktopXXL} {
    height: 120px;
  }
`
