import React from 'react'

import * as S from './_styles'

import { Button } from '@interco/inter-ui/components/Button'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const ValeAPenaVerDeNovo = () => {
  const [ sendDataLayer ] = useDataLayer()

  return (
    <S.ValeAPenaVerDeNovoSection
      className='section_wrapper'
      id='vale-a-pena-ver-de-novo'
    >
      <div className='container'>
        <div className='row'>
          <div className='section_wrapper__content'>
            <picture
              className='section_wrapper__content__video_thumbnail'
            >
              <img
                className='section_wrapper__content__video_thumbnail__source'
                alt='Foto de uma das lives da semana do empreendedor em 2023.  Em destaque, duas participantes do evento. Ao fundo vemos o cenário.'
                src='https://central-imagens.bancointer.com.br/images-without-small-versions/semana-empresa-dobra3/image.webp'
              />
            </picture>

            <div className='section_wrapper__content__texts'>
              <h2 className='section_wrapper__content__texts__title'>
                Vale a pena ver de novo!
                <span className='section_wrapper__content__texts__title--highlighted'>
                  Confira a edição 2023
                </span>
              </h2>
              <Button
                as='a'
                href='https://www.youtube.com/watch?v=DQcihNib0oY&list=PLuZu2Av0_IiOaOkjhJaSjVeALaEG_gWUD'
                target='_blank'
                fullWidth
                className='section_wrapper__content__texts__button'
                onClick={() => {
                  sendDataLayer({
                    section: 'dobra_03',
                    section_name: 'Vale a pena ver de novo',
                    element_name: 'Conferir agora',
                    element_action: 'click button',
                    redirect_url: 'https://www.youtube.com/watch?v=DQcihNib0oY&list=PLuZu2Av0_IiOaOkjhJaSjVeALaEG_gWUD',
                  })
                }}
              >
                Conferir agora
              </Button>
            </div>
          </div>
        </div>
      </div>
    </S.ValeAPenaVerDeNovoSection>
  )
}

export default ValeAPenaVerDeNovo
