import styled from "styled-components"
import * as newColors from 'src/styles/newColors'
import { device } from "src/styles/breakpoints"

export const UmaSemanaDeLivesEOfertasSection = styled.section`
  padding: 40px 0;
  background-color: ${newColors.orange.clay};

  @media ${device.desktopLG} {
    padding: 80px 0;
  }

  .section_wrapper {
    &__content {

      @media ${device.tablet}{
        display: flex;
        align-items: center;
        justify-content: space-around;

        padding: 0px 30px;
      }

      @media ${device.desktopLG} {
        justify-content: space-between;
      }

      @media ${device.desktopXXL} {
        justify-content: space-evenly;
      }

      &__graphism {
        display: none;
        
        @media ${device.tablet}{
          display: flex;
          width: 32%;
          height: fit-content;
        }
        
        @media ${device.desktopLG} {
          width: 36%;
        }

        @media ${device.desktopXXL} {
          width: 30%;
        }

        &__source {
          width: 100%;
          height: fit-content;
        }
      }

      &__texts {
        width: 100%;

        @media ${device.tablet} {
          width: 51%;
        }

        @media ${device.desktopLG} {
          width: 57%;
        }

        @media ${device.desktopXXL} {
          width: 47%;
        }

        &__title {
          font-family: 'Citrina', sans-serif;
          font-size: 24px;
          line-height: 29px;
          font-weight: 500;
          color: ${newColors.sand};
          width: 80%;

          @media ${device.tablet} {
            width: 100%;
          }

          @media ${device.desktopLG} {
            font-size: 36px;
            line-height: 43px;
          }

        }
        &__description {
          color: ${newColors.sand};
          font-size: 18px;
          line-height: 22px;
          font-weight: 400;
          font-family: Inter, sans-serif;
          margin: 24px 0;

          @media ${device.tablet}{
            font-size: 16px;
          }

          @media ${device.desktopLG} {
            font-size: 18px;
          }
        }
      }
    }
  }
`
